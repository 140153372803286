<template>
  <div class='ym-page'>
    <el-card>
      <!-- 顶部组件 -->
      <div slot='header'
           class='clearfix'>
        <el-row>
          <el-col :xl='12'
                  :lg='12'
                  :md='12'
                  :xs='12'>
            <div class='line-name'>
              <span>二进制部署</span>
            </div>
          </el-col>
          <el-col :xl='12'
                  :lg='12'
                  :md='12'
                  :xs='12'>
            <!-- <el-button v-if="!isAddBtn"
                       style="float: right;margin-left: 10px;"
                       type="primary"
                       icon="el-icon-plus"
                       size="small"
                       @click="addNode()">添加
            </el-button>
            <el-button v-else
                       style="float: right"
                       type="primary"
                       icon="el-icon-s-home"
                       size="small"
                       @click="toHome()">首页
            </el-button> -->
            <el-button class="reset-btn"
                       v-if="this.deployText === '一键部署'"
                       style="float: right;margin-left: 10px;"
                       icon="el-icon-refresh-right"
                       size="small"
                       @click="reset()">一键重置</el-button>
            <el-button v-if="this.deployText === '重新部署'"
                       style="float: right;margin-left: 10px;"
                       icon="el-icon-edit-outline"
                       size="small"
                       @click="initForm()">返回表单</el-button>
            <el-button class="deploy-btn"
                       v-if="isDeployBtn !== 3"
                       style="float: right;"
                       :icon="isDeployBtn === 2?'el-icon-loading':'el-icon-upload'"
                       type="primary"
                       size="small"
                       :disabled="isDeployBtn === 2"
                       @click="submitForm()">{{deployText}}</el-button>
            <el-button v-if="isDeployBtn === 3"
                       style="float: right;"
                       icon="el-icon-edit-outline"
                       size="small"
                       @click="initForm()">返回表单</el-button>
          </el-col>
        </el-row>
      </div>
      <!-- 节点卡片 -->
      <el-row :gutter='10'>
        <el-card class="Bottom small-left small-right"
                 v-for="(node,index) in nodeList"
                 :key='index'
                 shadow="never">

          <!-- 状态提示 -->
          <el-row>
            <el-col v-if="node.code === 201"
                    class="BigBottom"
                    :xl='24'
                    :lg='24'
                    :md='24'
                    :xs='24'>
              <el-alert title="部署成功"
                        type="success"
                        :description="node.host + ':'+ node.networkPort +' 区块链节点部署成功！'"
                        show-icon
                        :closable="false">
              </el-alert>
            </el-col>
            <el-col v-else-if="node.code === 400"
                    class="BigBottom"
                    :xl='24'
                    :lg='24'
                    :md='24'
                    :xs='24'>
              <el-alert title="部署失败"
                        type="error"
                        :description="node.host + ':' + node.networkPort + ' 区块链节点部署失败：' + node.message"
                        show-icon
                        :closable="false">
              </el-alert>
            </el-col>
            <!-- <el-col v-else-if="node.code === 100"
                      class="BigBottom"
                      :xl='24'
                      :lg='24'
                      :md='24'
                      :xs='24'>
                <el-alert title="未部署"
                          type="warning"
                          :description="'该区块链节点暂未部署！'"
                          show-icon
                          :closable="false">
                </el-alert>
              </el-col> -->
            <el-col v-else-if="node.code === 200"
                    class="BigBottom"
                    :xl='24'
                    :lg='24'
                    :md='24'
                    :xs='24'>
              <el-alert title="正在部署"
                        type="info"
                        :description="node.host + ':' + node.networkPort + ' 区块链节点正在部署：' + node.message"
                        show-icon
                        :closable="false">
              </el-alert>
            </el-col>
          </el-row>

          <!-- 表单 -->
          <el-form :model="node"
                   :rules="rules"
                   ref="node"
                   label-position="left"
                   class="demo-ruleForm">

            <el-col class="smallBottom"
                    :xl='12'
                    :lg='12'
                    :md='12'
                    :xs='12'>
              <span>节点{{index+1}}</span>
            </el-col>
            <el-col class="smallBottom"
                    :xl='12'
                    :lg='12'
                    :md='12'
                    :xs='12'>
              <el-button v-if="index !== 0 && !node.isShow && isDeployBtn !== 4"
                         class="mini-btn color--danger"
                         type="text"
                         size="mini"
                         icon="el-icon-delete"
                         @click="deleteNode(index)">删除</el-button>
              <el-button v-if="!node.open"
                         class="mini-btn color--info"
                         type="text"
                         size="mini"
                         icon="el-icon-arrow-down"
                         @click="isOpen(index)">配置</el-button>
              <el-button v-else
                         class="mini-btn color--info"
                         type="text"
                         size="mini"
                         icon="el-icon-arrow-up"
                         @click="isOpen(index)">配置</el-button>
              <el-button v-if="!node.isShow && isDeployBtn !== 4"
                         class="mini-btn copy-btn"
                         type="text"
                         icon="el-icon-plus"
                         size="mini"
                         @click="addCopyNode(index)">复制</el-button>
            </el-col>
            <!-- 第一行 -->
            <el-col :xl='4'
                    :lg='4'
                    :md='24'
                    :xs='24'>
              <el-form-item label="服务器地址"
                            prop="host">
                <el-input v-model="node.host"
                          size="mini"
                          placeholder="请填写服务器地址"
                          :disabled='node.isShow'
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :xl='4'
                    :lg='4'
                    :md='24'
                    :xs='24'>
              <el-form-item label="SSH端口"
                            prop="port">
                <el-input v-model="node.port"
                          type="number"
                          size="mini"
                          placeholder="请填写SSH端口"
                          :disabled='node.isShow'
                          clearable>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :xl='4'
                    :lg='4'
                    :md='24'
                    :xs='24'>
              <el-form-item label="SSH用户名"
                            prop="user">
                <el-input v-model="node.user"
                          placeholder="请填写SSH用户名"
                          maxlength="50"
                          size="mini"
                          :disabled='node.isShow'
                          clearable>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :xl='4'
                    :lg='4'
                    :md='24'
                    :xs='24'>
              <el-form-item label="SSH密码"
                            prop="pass">
                <el-input v-model="node.pass"
                          type="password"
                          placeholder="请填写SSH密码"
                          maxlength="30"
                          size="mini"
                          :disabled='node.isShow'
                          clearable>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :xl='4'
                    :lg='4'
                    :md='24'
                    :xs='24'>
              <el-form-item label="证书"
                            prop="ca">
                <el-input v-model="node.ca"
                          placeholder="请填写证书"
                          size="mini"
                          :disabled='node.isShow'
                          clearable>
                </el-input>
              </el-form-item>
            </el-col>

            <div v-show="node.open">
              <el-col class="Bottom"
                      :xl='24'
                      :lg='24'
                      :md='24'
                      :xs='24'>
                <span>详细配置</span>
              </el-col>
              <!-- 第二行 -->
              <el-col class="smallBottom"
                      :xl='24'
                      :lg='24'
                      :md='24'
                      :xs='24'>
                <span class="setting-title">软件配置</span>
              </el-col>
              <el-col :xl='8'
                      :lg='8'
                      :md='24'
                      :xs='24'>
                <el-form-item label="部署路径"
                              prop="path">
                  <el-input v-model="node.path"
                            placeholder="请填写部署路径"
                            :disabled='node.isShow'
                            size="mini"
                            clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl='8'
                      :lg='8'
                      :md='24'
                      :xs='24'>
                <el-form-item label="运行环境"
                              prop="env">
                  <el-select class="select-box"
                             v-model="node.env"
                             placeholder="请选择JDK版本"
                             :disabled='node.isShow'
                             size="mini"
                             clearable>
                    <el-option v-for="item in JDKOpt"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xl='8'
                      :lg='8'
                      :md='24'
                      :xs='24'>
                <el-form-item label="客户端版本"
                              prop="client">
                  <el-select class="select-box"
                             v-model="node.client"
                             size="mini"
                             placeholder="请选择节点客户端版本"
                             :disabled='node.isShow'
                             clearable>
                    <el-option v-for="item in versionOpt"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 第三行 -->
              <el-col class="smallBottom"
                      :xl='24'
                      :lg='24'
                      :md='24'
                      :xs='24'>
                <span class="setting-title">安装配置</span>
              </el-col>
              <el-col :xl='3'
                      :lg='3'
                      :md='24'
                      :xs='24'>
                <el-form-item label="network.port"
                              prop="networkPort">
                  <el-input v-model="node.networkPort"
                            type="number"
                            size="mini"
                            placeholder="请填写本机网络端口"
                            :disabled='node.isShow'
                            clearable>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :xl='3'
                      :lg='3'
                      :md='24'
                      :xs='24'>
                <el-form-item label="peer.type"
                              prop="peerType">
                  <el-input v-model="node.peerType"
                            placeholder="请填写节点类型"
                            clearable
                            size="mini"
                            disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl='3'
                      :lg='3'
                      :md='24'
                      :xs='24'>
                <el-form-item label="peer.name"
                              prop="peerName">
                  <el-select v-model="node.peerName"
                             size="mini"
                             :disabled='node.isShow'>
                    <el-option v-for="item in options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xl='3'
                      :lg='3'
                      :md='24'
                      :xs='24'>
                <el-form-item label="algorithm.account"
                              prop="algorithmAccount">
                  <el-select class="select-box"
                             v-model="node.algorithmAccount"
                             placeholder="请选择账户生成方式"
                             :disabled='node.isShow'
                             size="mini"
                             clearable>
                    <el-option v-for="item in accountOpt"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xl='3'
                      :lg='3'
                      :md='24'
                      :xs='24'>
                <el-form-item label="algorithm.password"
                              prop="algorithmPassword">
                  <el-select class="select-box"
                             v-model="node.algorithmPassword"
                             placeholder="请选择密码加密方式"
                             :disabled='node.isShow'
                             size="mini"
                             clearable>
                    <el-option v-for="item in passwordOpt"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xl='3'
                      :lg='3'
                      :md='24'
                      :xs='24'>
                <el-form-item label="network.type"
                              prop="networkType">
                  <el-select class="select-box"
                             size="mini"
                             v-model="node.networkType"
                             placeholder="请选择网络类型"
                             :disabled='node.isShow'
                             clearable>
                    <el-option v-for="item in typeOpt"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xl='3'
                      :lg='3'
                      :md='24'
                      :xs='24'>
                <el-form-item label="algorithm.hash"
                              prop="algorithmHash">
                  <el-select class="select-box"
                             v-model="node.algorithmHash"
                             placeholder="请选择哈希算法"
                             :disabled='node.isShow'
                             size="mini"
                             clearable>
                    <el-option v-for="item in hashOpt"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

            </div>
          </el-form>
        </el-card>
      </el-row>

      <!-- 日志卡片 -->
      <el-row :gutter='32'>
        <el-card shadow="never"
                 class="small-left small-right show-log-box">
          <el-col class="smallBottom"
                  :xl='12'
                  :lg='12'
                  :md='12'
                  :xs='12'>
            <span>日志输出</span>
          </el-col>
          <!-- 日志框 -->
          <el-col :xl='24'
                  :lg='24'
                  :md='24'
                  :xs='24'
                  id="box"
                  class="log-box smallBottom"
                  :style="[{height:logHeight+'px'}]">
            <!-- 日志内容 -->
            <pre v-html="logData"></pre>
          </el-col>
        </el-card>
      </el-row>

    </el-card>

  </div>
</template>

<script>
import {
  getShardName,
  setDeployMsg,
  getDeployMsg,
  removeDeployMsg,
  setDeployBtn,
  getDeployBtn,
  removeDeployBtn
} from '@/utils/localStorage.js'
import timeFormat from '@/utils/timeFormat.js'
export default {
  data() {
    return {
      logHeight: '', // 日志框动态高度
      // 客户端版本可选项
      versionOpt: [],
      // 运行环境
      JDKOpt: [],
      // 账户生成算法
      accountOpt: [
        {
          value: 'sm2',
          label: 'sm2'
        },
        {
          value: 'rsa',
          label: 'rsa'
        }
      ],
      // 密码生成算法
      passwordOpt: [
        {
          value: 'sm4',
          label: 'sm4'
        },
        {
          value: 'aes',
          label: 'aes'
        }
      ],
      // 加密算法
      hashOpt: [
        {
          value: 'sm3',
          label: 'sm3'
        },
        {
          value: 'sha3',
          label: 'sha3'
        }
      ],
      // 网络类型
      typeOpt: [
        {
          value: 'main',
          label: 'main'
        },
        {
          value: 'test',
          label: 'test'
        }
      ],
      deployment: [], // 部署返回信息
      isAddBtn: false, // 是否显示添加按钮
      isDeployBtn: 1, // 部署按钮(1:显示一键部署、2：显示部署中...、3：显示部署完成、4：显示重新部署)
      deployText: '一键部署', // 部署按钮文案
      // 表单列表信息
      nodeList: [
        {
          isShow: false, // 是否编辑状态
          open: false, // 是否打开详细配置
          code: 100, // 100：表示该节点未部署 201：部署成功 400：部署失败
          message: '',
          host: '', // 主机地址
          port: 22, // 主机端口
          user: '', // 登录用户
          pass: '', // 登录密码
          ca: '', // 证书
          path: '/opt', // 部署路径
          client: '', // 节点客户端版本
          env: '', // 运行环境
          networkPort: 9705, // network.port = 9705
          peerType: 'shard', // peer.type = shard
          peerName: '', // peer.name = Test
          algorithmAccount: 'rsa', // algorithm.account = sm2/rsa
          algorithmPassword: 'aes', // algorithm.password = sm4/aes
          networkType: 'main', // network.type = test
          algorithmHash: 'sha3' // algorithm.hash = sm3/sha3
        }
      ],
      rules: {
        host: [
          {
            required: true,
            pattern:
              '^((25[0-5]|2[0-4]\\d|[1]{1}\\d{1}\\d{1}|[1-9]{1}\\d{1}|\\d{1})($|(?!\\.$)\\.)){4}$',
            message: '请输入正确的服务器地址',
            trigger: 'blur'
          }
        ],
        port: [
          {
            required: true,
            // 端口号：1~65535
            pattern:
              /^([1-9]|[1-9]\d|[1-9]\d{2}|[1-9]\d{3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/,
            message: '请输入正确的SSH端口',
            trigger: 'blur'
          }
        ],
        user: [{ required: true, message: '请输入SSH用户', trigger: 'blur' }],
        pass: [{ required: true, message: '请输入SSH密码', trigger: 'blur' }],
        ca: [{ required: true, message: '请输入证书', trigger: 'blur' }],
        path: [{ required: true, message: '请输入部署路径', trigger: 'blur' }],
        client: [
          {
            required: true,
            message: '请选择节点客户端版本',
            trigger: 'change'
          }
        ],
        env: [
          {
            required: true,
            message: '请选择JDK版本',
            trigger: 'change'
          }
        ],
        networkPort: [
          {
            required: true,
            pattern:
              /^([1-9]|[1-9]\d|[1-9]\d{2}|[1-9]\d{3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/,
            message: '请输入正确的网络端口',
            trigger: 'blur'
          }
        ],
        peerType: [
          { required: true, message: '请输入节点类型', trigger: 'blur' }
        ],
        peerName: [
          { required: true, message: '请输入自定义分片名称', trigger: 'blur' }
        ],
        algorithmAccount: [
          { required: true, message: '请选择账户生成方式', trigger: 'change' }
        ],
        algorithmPassword: [
          { required: true, message: '请选择密码加密方式', trigger: 'change' }
        ],
        networkType: [
          { required: true, message: '请选择网络类型', trigger: 'change' }
        ],
        algorithmHash: [
          { required: true, message: '请选择节哈希算法', trigger: 'change' }
        ]
      },
      options: [],
      path: '', // Websocket地址
      socket: '', // Websocket对象
      logData: '', // 日志输出
      storageData: {
        log: '',
        stateList: []
      } // 缓存信息
    }
  },
  computed: {},
  created() {
    // 判断有没分片缓存
    if (getShardName()) {
      this.getAliases()
      this.nodeList[0].peerName = getShardName()
    } else {
      this._getAliases()
    }
    this.getClientList()
    this.getEnvList()
    this.logHeight = window.innerHeight - 490
    // this.logHeight = window.innerHeight - 605
    const baseUrl = window.baseUrl.split('//')
    this.path = 'ws://' + baseUrl[1] + '/binary/deploy'

    // 恢复按钮(1:显示一键部署、2：显示部署中...、3：显示部署完成、4：显示重新部署)
    if (getDeployBtn()) {
      if (getDeployBtn() === 1) {
        // 显示一键部署按钮
        this.isDeployBtn = 1
        this.deployText = '一键部署'
      } else if (getDeployBtn() === 2) {
        this.isDeployBtn = 2
        this.deployText = '部署中...'
      } else if (getDeployBtn() === 3) {
        this.isDeployBtn = 3
        this.deployText = '部署完成'
      } else if (getDeployBtn()) {
        this.isDeployBtn = 4
        this.deployText = '重新部署'
      }
    }
    // 恢复表单内容
    if (getDeployMsg()) {
      this.nodeList = getDeployMsg()
    }
    // 判断有信息缓存时，显示节点状态与日志输出
    if (sessionStorage.getItem('watchStorage')) {
      let dataStorage = JSON.parse(sessionStorage.getItem('watchStorage'))
      // 显示节点最新状态
      this.getData(dataStorage.stateList)
      // 显示日志输出
      this.logData = dataStorage.log
    }
    // 监听缓存信息变化并正确显示节点状态与日志输出
    window.addEventListener('setItem', () => {
      let dataStorage = JSON.parse(sessionStorage.getItem('watchStorage'))
      // 显示最新日志输出与节点最新状态
      this.getData(dataStorage.stateList)
      // 显示日志输出
      this.logData = dataStorage.log
    })
  },
  mounted() {
    // 新手指引
    this.$nextTick(() => {
      this.$guide.myIntroJs(this.$route.name)
    })
  },
  destroyed() {
    // 判断是否已经部署完成
    if (this.logData.indexOf(`[ALL DONE]`) !== -1) {
      // console.log('关闭页面时监听')
      // 恢复部署按钮
      this.isDeployBtn = 1
      this.deployText = '一键部署'
      // 清除缓存信息
      sessionStorage.removeItem('watchStorage')
      // 清除缓存表单
      removeDeployMsg()
      // 清除按钮状态
      removeDeployBtn()
    }
  },
  watch: {
    logData() {
      this.$nextTick(() => {
        var div = document.getElementById('box')
        div.scrollTop = div.scrollHeight
      })
    }
  },
  methods: {
    // 初始化Websocket链接
    initWebsocket() {
      if (typeof WebSocket === 'undefined') {
        this.$message.error('您的浏览器不支持Websocket')
      } else {
        // 实例化socket
        this.socket = new WebSocket(this.path)
        // 监听socket连接
        this.socket.onopen = this.open
        // 监听socket错误信息
        this.socket.onerror = this.error
        // 监听socket消息
        this.socket.onmessage = this.getMessage
        // 监听关闭
        // this.socket.onclose = this.close
      }
    },
    // 打开连接
    open() {
      console.log('socket连接成功')
    },
    // 返回错误
    error() {
      console.log('连接错误')
    },
    send(nodeList) {
      this.socket.send(nodeList)
    },
    // 根据缓存信息轮询判断显示节点状态
    getData(data) {
      for (let item of data) {
        // 处理节点信息
        this.getNodeInfo(item)
      }
    },
    // 处理节点信息
    getNodeInfo(data) {
      // 判断每一步的结果并显示到节点状态（出现401表示全局错误，终止部署）
      if (data.code === 201) {
        // 节点部署成功
        this.nodeList[data.range].code = data.code
        this.nodeList[data.range].isShow = true
      } else if (data.code === 400) {
        // 节点部署失败
        this.nodeList[data.range].code = data.code
        this.nodeList[data.range].message = data.message
      } else if (data.code === 200) {
        this.nodeList[data.range].code = data.code
        this.nodeList[data.range].message = data.message
      } else if (data.code === 0) {
        // 部署完成，需要判断是否有节点部署不成功
        let nodeList = JSON.stringify(this.nodeList)
        if (nodeList.indexOf(`"code":400`) !== -1) {
          // 显示重新部署按钮
          this.isDeployBtn = 4
          this.deployText = '重新部署'
          setDeployBtn(4)
          // 恢复失败节点的表单为编辑状态
          for (let node of this.nodeList) {
            if (node.code !== 201) {
              node.isShow = false
            }
          }
        } else {
          // 显示部署按钮
          this.isDeployBtn = 3
          this.deployText = '部署完成'
          setDeployBtn(3)
        }
      } else if (data.code === 401) {
        // 恢复表单编辑状态
        for (let node of this.nodeList) {
          node.isShow = false
        }
        // 出现全局错误（此时已经终止部署），显示重新部署按钮
        this.isDeployBtn = 4
        this.deployText = '重新部署'
        setDeployBtn(4)
      }
    },
    // 获取响应数据
    getMessage(msg) {
      let data = JSON.parse(msg.data)
      // console.log('消息：', data)
      // 处理节点信息
      this.getNodeInfo(data)
      // 实时显示日志输出
      // let logData = this.logData
      // this.logData = logData.concat(this.getLog(data))
      this.logData += this.getLog(data)
      // 响应信息缓存
      let obj = {}
      obj.code = data.code
      obj.range = data.range
      obj.message = data.message
      this.storageData.stateList.push(obj)
      this.storageData.log = this.logData
      this.resetSetItem('watchStorage', JSON.stringify(this.storageData))
      // console.log('缓存数组:', this.storageData)
    },
    // 关闭连接
    close() {
      console.log('socket连接已经关闭')
    },

    // 获取当前日期时间
    getTime() {
      let date = new Date()
      return this.tf(date, 'HH:mm:ss ')
    },
    // 时间转换
    tf(time, format) {
      return timeFormat(time, format)
    },

    // 日志消息格式处理
    getLog(data) {
      // console.log(data)
      if (data.code === 200) {
        let cls = 'infoCls'
        return (
          '<span class="' +
          cls +
          '">' +
          this.getTime() +
          '[INFO]：' +
          data.message +
          '</span>'
        )
      } else if (data.code === 0) {
        let cls = 'finishCls'
        return (
          '<span class="' +
          cls +
          '">' +
          this.getTime() +
          '[FINISH]：' +
          data.message +
          '</span>'
        )
      } else if (data.code === 201) {
        let cls = 'successCls'
        return (
          '<span class="' +
          cls +
          '">' +
          this.getTime() +
          '[SUCCESS]：' +
          data.message +
          '</span>'
        )
      } else if (data.code === 400) {
        let cls = 'errorCls'
        return (
          '<span class="' +
          cls +
          '">' +
          this.getTime() +
          '[ERROR]：' +
          data.message +
          '</span>'
        )
      } else if (data.code === 401) {
        let cls = 'errorCls'
        return (
          '<span class="' +
          cls +
          '">' +
          this.getTime() +
          '[GLOBAL ERROR]：' +
          data.message +
          '</span>'
        )
      } else {
        let cls = 'infoCls'
        return (
          '<span class="' +
          cls +
          '">' +
          this.getTime() +
          '[INFO]：' +
          data.message +
          '</span>'
        )
      }
    },

    // 重置节点
    reset() {
      // 重置表单
      let nodeList = this.nodeList
      // 如果是部署成功的节点不给重置数据，避免无效部署
      for (let node of nodeList) {
        if (node.code === 201) {
          node.isShow = true
        } else {
          node.isShow = false
          node.open = false
          node.host = ''
          node.port = 22
          node.path = '/opt'
          node.user = ''
          node.pass = ''
          node.ca = ''
          node.client = this.versionOpt[0].value // 节点客户端版本
          node.env = this.JDKOpt[0].value // 运行环境
        }
      }
      this.$nextTick(() => {
        this.nodeList = nodeList
      })

      // 消除所有表单提示
      for (let item of this.$refs.node) {
        item.clearValidate()
      }
      // 清除缓存信息
      sessionStorage.removeItem('watchStorage')
      // 清除数据域缓存日志
      this.logData = ''
      // 清除缓存表单
      removeDeployMsg()
    },

    // 初始化表单
    initForm() {
      let array = []
      array.push(this.init())
      this.nodeList = array
      // 消除所有表单提示
      for (let item of this.$refs.node) {
        item.resetFields()
      }
      // 清除缓存信息
      sessionStorage.removeItem('watchStorage')
      // 清除数据域缓存日志
      this.logData = ''
      // 清除缓存表单
      removeDeployMsg()
      // 显示部署按钮
      this.isDeployBtn = 1
      this.deployText = '一键部署'
      setDeployBtn(1)
    },

    // 初始化表单数据
    init() {
      let nodeForm = {
        isShow: false, // 是否编辑状态
        open: false, // 是否打开详细配置
        code: 100, // 100：表示该节点未部署 201：部署成功 400：部署失败
        message: '',
        host: '', // 主机地址
        port: 22, // 主机端口
        user: '', // 登录用户
        pass: '', // 登录密码
        ca: '', // 证书
        path: '/opt', // 部署路径
        client: this.versionOpt[0].value, // 节点客户端版本
        env: this.JDKOpt[0].value, // 运行环境
        networkPort: 9705, // network.port = 9705
        peerType: 'shard', // peer.type = shard
        peerName: '', // peer.name = Test
        algorithmAccount: 'rsa', // algorithm.account = sm2/rsa
        algorithmPassword: 'aes', // algorithm.password = sm4/aes
        networkType: 'main', // network.type = test
        algorithmHash: 'sha3' // algorithm.hash = sm3/sha3
      }
      return nodeForm
    },
    // 一键复制并添加
    addCopyNode(index) {
      // console.log('添加第' + index + '项,为：' + JSON.stringify(this.nodeList[index]))
      // 判断是否大于10个节点
      if (this.nodeList.length < 10) {
        var node = JSON.parse(JSON.stringify(this.nodeList[index]))
        node.host = ''
        node.user = ''
        node.pass = ''
        node.open = false
        node.isShow = false
        node.code = 100
        this.nodeList.push(node)
      } else {
        // 判断消息提示条数
        if (document.getElementsByClassName('el-message').length > 2) return
        this.$message.warning('一次最多只能部署10个区块链节点！')
      }
    },
    // 删除节点表单
    deleteNode(index) {
      // console.log('删除第' + index + '项')
      this.$confirm('此操作将永久删除该条记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.nodeList.splice(index, 1)
      })
    },
    // 是否打开详细配置
    isOpen(index) {
      // console.log('打开第' + this.nodeList[index] + '项')
      this.nodeList[index].open = !this.nodeList[index].open
      // console.log('当前数组:', this.nodeList)
    },

    // 提交所有表单
    submitForm() {
      // 排队校检表单
      let temp = []
      for (let item of this.$refs['node']) {
        temp.push(
          new Promise((resolve, reject) => {
            item.validate(async (valid) => {
              if (valid) {
                resolve('true')
              } else {
                resolve('false')
              }
            })
          })
        )
      }
      Promise.all(temp)
        .then((res) => {
          // console.log(res)
          // 判断是否有检验不正确
          if (res.indexOf('false') === -1) {
            let nodeData = this.nodeList
            let array = []
            for (let node of nodeData) {
              node.open = false
              node.isShow = true
              if (node.code === 100 || node.code === 400) {
                array.push(node)
              }
            }
            this.nodeList = array
            // 保存表单内容
            setDeployMsg(this.nodeList)
            // 清除日志缓存
            this.logData = ''
            // 清除缓存信息
            sessionStorage.removeItem('watchStorage')
            // 初始化本地缓存信息
            this.storageData.log = ''
            this.storageData.stateList = []
            // 设置按钮(2：正在部署)
            setDeployBtn(2)
            this.isDeployBtn = 2
            this.deployText = '部署中...'
            // 开始初始化socket连接
            this.initWebsocket()
            let nodeList = JSON.parse(JSON.stringify(this.nodeList))
            // 提交表单1秒后开始发送数据
            setTimeout(() => {
              // 添加状态判断，当为OPEN时，发送消息
              if (this.socket.readyState === 1) {
                this.send(JSON.stringify(nodeList))
              } else if (this.socket.readyState === 0) {
                // this.$message.success('正在连接！')
                setTimeout(() => {
                  this.send(JSON.stringify(nodeList))
                }, 1000)
              } else {
                setTimeout(() => {
                  // 正在关闭或者已经关闭连接时
                  // 开始初始化socket连接
                  this.initWebsocket()
                  setTimeout(() => {
                    this.send(JSON.stringify(nodeList))
                  }, 1000)
                }, 1000)
              }
            }, 1000)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取客户端版本列表
    async getClientList() {
      let { code, data } = await this.$api.home.clientList()
      if (code === 200) {
        this.nodeList[0].client = data[0]
        for (let item of data) {
          let obj = {}
          obj.value = item
          obj.label = item
          this.versionOpt.push(obj)
        }
      }
    },
    // 获取JDK版本列表
    async getEnvList() {
      let { code, data } = await this.$api.home.envList()
      if (code === 200) {
        this.nodeList[0].env = data[0]
        for (let item of data) {
          let obj = {}
          obj.value = item
          obj.label = item
          this.JDKOpt.push(obj)
        }
      }
    },
    // 获取分片数组
    async getAliases() {
      let { data } = await this.$api.home.shard()
      if (data) {
        let array = []
        for (let item of data) {
          let obj = {}
          obj.value = item
          obj.label = item
          array.push(obj)
        }
        this.options = array
      }
    },
    // 获取分片数组并赋值
    async _getAliases() {
      let { data } = await this.$api.home.shard()
      if (data) {
        let array = []
        for (let item of data) {
          let obj = {}
          obj.value = item
          obj.label = item
          array.push(obj)
        }
        this.options = array
        this.nodeList[0].peerName = array[0].value
      }
    },
    toHome() {
      this.$router.push('/home')
    }
    // 添加节点表单
    // addNode() {
    //   // 判断是否大于10个节点
    //   if (this.nodeList.length < 10) {
    //     this.nodeList.push(this.init())
    //   } else {
    //     // 判断消息提示条数
    //     if (document.getElementsByClassName('el-message').length > 2) return
    //     this.$message.warning('一次最多只能部署10个区块链节点！')
    //   }
    // },
  },
  filters: {
    priceFilter(val) {
      if (!val) {
        return
      }
      return parseFloat(val).toFixed(2)
    }
  }
}
</script>

<style lang='scss' scoped>
/deep/ .el-col-lg-8 {
    width: 27.8%;
}
/deep/ .el-col-lg-3 {
  width: 11.9%;
}
/deep/.el-form-item__label {
  font-size: 12px;
}
.setting-title {
  font-size: 13px;
}
/deep/.el-card__header {
  padding: 20px 20px 12px 20px;
  border-bottom: 1px solid #ebeef5;
  box-sizing: border-box;
}
// 日志框
.log-box {
  margin-left: 16px;
  margin-right: 16px;
  width: calc(100% - 32px);
  background: rgba(0, 0, 20, 0.8);
  overflow-x: hidden;
  overflow-y: scroll;
}
/deep/ pre {
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 28px;
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  .infoCls {
    color: white;
    overflow-wrap: break-word;
  }
  .finishCls {
    color: rgb(57, 120, 255);
    overflow-wrap: break-word;
  }
  .successCls {
    color: $--color-success;
    overflow-wrap: break-word;
  }
  .errorCls {
    color: $--color-danger;
    overflow-wrap: break-word;
  }
}
.mini-btn {
  padding: 5px;
  float: right;
  margin-left: 5px;
}
.color--danger {
  color: $--color-danger;
}
.color--info {
  color: $--color-warning;
}
.input {
  width: 40px;
}
.select {
  width: 120px;
  margin-right: 20px;
}
.counts-title {
  color: $--color-primary;
  font-size: 28px;
  font-weight: 600;
  margin-right: 10px;
}
.line-name {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  color: #222b45;
  font-weight: 600;
}
.pre-title {
  border: 1px solid #f08519;
  margin-right: 6px;
}
/deep/.el-input {
  width: 100%;
}
.select-box {
  width: 100%;
}
.small-left {
  margin-left: 12px;
}
.small-right {
  margin-right: 12px;
}
.smallBottom {
  margin-bottom: 12px;
}
.Bottom {
  margin-bottom: 20px;
}
.BigBottom {
  margin-bottom: 40px;
}
.Left {
  margin-left: 10px;
}
/* -- 滚动条 start -- */
::v-deep {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* !*定义滚动条轨道 内阴影+圆角*! */
  ::-webkit-scrollbar-track {
    /*滚动条里面小方块*/
    background: rgba(255, 255, 255, 0.1);
  }

  /*!*定义滑块 内阴影+圆角*!*/
  ::-webkit-scrollbar-thumb {
    /*滚动条里面轨道*/
    // border-radius: 10px;
    background: rgba(255, 255, 255, 0.4);
  }
}
/* -- 滚动条 end -- */
</style>
